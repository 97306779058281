import React from 'react';

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
  id: string,
  name: string,
  required: boolean,
  moveLabel: boolean,
  error?: boolean,
  type: string,
  placeholder: string,
  readOnly?: boolean
}

const TextInput = ({ id, name, required, moveLabel, error, type, placeholder, readOnly, ...props }: InputProps) => {
  return (
    <>
      <div className="mt-8 w-72">
        <div className="relative h-10 w-full min-w-[200px] ">
          <input
            readOnly={readOnly}
            id={id}
            placeholder={placeholder}
            type={type}
            value={placeholder}
            {...props}
            autoComplete='on'
            className={`
            ${error ? 'border-2 border-red-500 focus:border-red-500 focus:text-red-500 text-red-500 font-bold' : ''}
            peer 
            h-full 
            w-full 
            rounded-[7px]
            bg-transparent           
            px-3 
            py-2.5 
            font-sans 
            text-base 
            transition-all 
            text-gray-500
            focus:text-black
            focus:border-[#5608F8]
            border    
            focus:border-t-transparent 
            focus:outline-0 
            `}
          />
          <label className={`
            pointer-events-none 
            absolute left-2 
            ${moveLabel ? '-top-6' : 'top-2'}
            flex h-full w-full 
            select-none 
            text-[16px] 
            leading-tight 
            transition-all 
            peer-focus:-top-2
            peer-focus:text-[14px]
            peer-focus:leading-tight 
            font-semibold
            text-gray-500
            peer-focus:text-[#5608F8]
          `}>
            <span className={`${error ? 'text-red-500 font-bold' : ''}`}>{name}</span>&ensp;{required && <span className='font-bold text-red-600'>*</span>}
          </label>
        </div>
      </div>
    </>
  );
};

export default TextInput;