import { create } from "zustand";

interface Permissions {
  can_view_users: boolean;
  can_create_users: boolean;
  can_update_users: boolean;
  can_delete_users: boolean;
  can_view_projects: boolean;
  can_create_projects: boolean;
  can_update_projects: boolean;
  can_delete_projects: boolean;
  can_view_user_plans: boolean;
  can_create_user_plans: boolean;
  can_update_user_plans: boolean;
  can_delete_user_plans: boolean;
  can_view_user_payments: boolean;
  can_create_user_payments: boolean;
  can_update_user_payments: boolean;
  can_delete_user_payments: boolean;
  can_view_groups: boolean;
  can_create_groups: boolean;
  can_update_groups: boolean;
  can_delete_groups: boolean;
  can_view_cms_users: boolean;
  can_create_cms_users: boolean;
  can_update_cms_users: boolean;
  can_delete_cms_users: boolean;
}


interface UserPermissionStore {
  permissions: Permissions;
  // eslint-disable-next-line no-unused-vars
  setPermissions: (permissions: Permissions) => void;
}

export const userPermissionStore = create<UserPermissionStore>((set) => ({
  permissions: {} as Permissions,
  setPermissions: (permissions) => set({ permissions })
}));
