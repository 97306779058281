import { Suspense } from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import SingIn from './pages/SingIn';
import { privateRoutes, publicRoutes } from "./routes";

import PrivateRoute from "./PrivateRoute";

import CommonLoading from "./components/loader/CommonLoading";

function App() {
  return (
    <Suspense fallback={<div className="flex items-center justify-center w-full h-screen"><CommonLoading /></div>}>
      <Routes>
        <Route path="/" element={<Navigate to="/admin/signin" />} /> 

        <Route element={<PrivateRoute />}>
          {privateRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={route.element}
            />
          ))}
        </Route>

        <Route path={"/admin/signin"} element={<SingIn />} />

        {publicRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </Suspense>
  );
}

export default App;
