import { titleMap } from "../routes";

/**
 * Returns the title of a page based on it's route path.
 * @param {string} path - The path of the page route.
 * @returns {string} The title of the page with the site name appended.
 */
export const getTitleFromRoute = (path: string): string => {
  for (const key in titleMap) {
    const regex = new RegExp(key);
    if (regex.test(path)) {
      return `${titleMap[key as keyof typeof titleMap]} | Seovigil`;
    }
  }

  return 'Seovigil';
};