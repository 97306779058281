import { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import { ToastContainer } from 'react-toastify';

import App from "./App";

import CommonLoading from "./components/loader/CommonLoading";
import ErrorComponent from "./components/shared/ErrorComponent";

import { getTitleFromRoute } from "./utils/docTitle";

const BASE_URL = process.env.REACT_APP_API_URL;

const AppContainer = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkServerStatus = async () => {
      try {
        await fetch(`${BASE_URL}/server-status`);

      } catch (err) {
        setError("Server is down. Please try again");
        console.error("Error checking server status: ", err);
      } finally {
        setLoading(false);
      }
    };

    checkServerStatus();
  }, []);

  if (loading || error) {
    return (
      <div className='flex items-center justify-center h-screen'>
        {loading ? <CommonLoading /> : <ErrorComponent errorMessage={error} />}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{getTitleFromRoute(location.pathname)}</title>
      </Helmet>
      <ToastContainer />
      <App />
    </>
  );
};

export default AppContainer;