import { useEffect, useState } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import useUserData from './hooks/useUserData';
import { userPermissionStore } from './store';

import CommonLoading from './components/loader/CommonLoading';
import Leftbar from './components/shared/Leftbar';

const PrivateRoute = () => {
  const [showLeftbar, setShowLeftbar] = useState(false);
  const [loading, setLoading] = useState(true);

  const { isUserAuthorized, errorMessage, userData } = useUserData();

  const { permissions } = userPermissionStore();

  const toggleLeftbar = () => {
    setShowLeftbar(!showLeftbar);
  };

  useEffect(() => {
    if (permissions) {
      setLoading(false);
    }
  }, [permissions]);

  if (loading) {
    return <div className='flex items-center justify-center w-full h-screen'><CommonLoading /></div>;
  }

  return isUserAuthorized ? (
    <div className='flex justify-between overflow-hidden'>
      <Leftbar
        toggleLeftbar={toggleLeftbar}
        showLeftbar={showLeftbar}
        userData={userData}
      />

      <div className='flex w-full pt-4 pl-4 pr-4 overflow-auto md:pl-8'>
        <Outlet />
      </div>
    </div>
  ) : <Navigate to={`/admin/access-denied?message=${errorMessage}`} />;
};

export default PrivateRoute;