import { useEffect, useState } from 'react';

import { userPermissionStore } from '../store';
import { TAdminUser } from '../types';
import { API_CODES } from '../utils/statusCodes';

const useUserData = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  const [userData, setUserData] = useState<TAdminUser | null>(null);
  // By default isUserAuthorized is true, so that useEffect runs and it's updated later and router doesn't redirect to access-denied
  const [isUserAuthorized, setIsUserAuthorized] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { setPermissions } = userPermissionStore();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/check-login`, { credentials: 'include' });

        if (response.ok) {
          const data = await response.json();

          setUserData(data.data.user);
          setIsUserAuthorized(true);
          setErrorMessage(null);
          setPermissions(data.data.user.permissions);
        } else {
          if (response.status === API_CODES.UNAUTHORIZED || response.status === API_CODES.FORBIDDEN) {
            const error = await response.json();

            setUserData(null);
            setIsUserAuthorized(false);
            setErrorMessage(error.error);
          }
        }

      } catch (error: unknown) {
        setUserData(null);
        setIsUserAuthorized(false);
        setErrorMessage('Unkown Error Occured, Try Again!');

        if (error instanceof Error) {
          console.error(`Error checking authorization: ${error.message}`);
        } else {
          console.error(`Error checking authorization: Unknown error`);
        }
      }
    };
    getUserData();
  }, []);

  return {
    isUserAuthorized,
    userData,
    errorMessage
  };
};

export default useUserData;