import { lazy } from 'react';

import { FaRegUserCircle } from 'react-icons/fa';
import { GrGroup } from "react-icons/gr";
import { IoRocketOutline } from 'react-icons/io5';
import { MdDashboard } from 'react-icons/md';

const Dashboard = lazy(() => import('./pages/Dashboard'));

const Users = lazy(() => import('./pages/Users'));
const UserEdit = lazy(() => import('./pages/UserEdit'));
const EditUserPlan = lazy(() => import('./pages/EditUserPlan'));
const UserProjects = lazy(() => import('./pages/UserProjects'));

const Projects = lazy(() => import('./pages/Projects'));
const EditProject = lazy(() => import('./pages/EditProject'));
const CreateProject = lazy(() => import('./pages/CreateProject'));

const Groups = lazy(() => import('./pages/Groups'));
const CreateGroup = lazy(() => import('./pages/CreateGroup'));
const EditGroup = lazy(() => import('./pages/EditGroup'));
const GroupUsers = lazy(() => import('./pages/GroupUsers'));
const AddUserInGroup = lazy(() => import('./pages/AddUserInGroup'));

const CMSUsers = lazy(() => import('./pages/CMSUsers'));
const CreateCMSUser = lazy(() => import('./pages/CreateCMSUser'));
const EditCMSUser = lazy(() => import('./pages/EditCMSUser'));

const NotFound = lazy(() => import('./pages/NotFound'));
const AccessDenied = lazy(() => import('./pages/AccessDenied'));
const LoginVerified = lazy(() => import('./pages/LoginVerified'));

export const privateRoutes = [
  {
    path: '/admin/dashboard',
    element: <Dashboard />
  },
  {
    path: '/admin/users',
    element: <Users />
  },
  {
    path: '/admin/users/:userID/edit',
    element: <UserEdit />
  },
  {
    path: '/admin/users/:userID/plans/edit',
    element: <EditUserPlan />
  },
  {
    path: '/admin/users/:userID/projects',
    element: <UserProjects />
  },
  {
    path: '/admin/users/:userID/projects/add',
    element: <CreateProject />
  },
  {
    path: '/admin/projects',
    element: <Projects />
  },
  {
    path: '/admin/projects/:projectID/edit',
    element: <EditProject />
  },
  {
    path: '/admin/groups',
    element: <Groups />
  },
  {
    path: '/admin/groups/add',
    element: <CreateGroup />
  },
  {
    path: '/admin/groups/:groupID/edit',
    element: <EditGroup />
  },
  {
    path: '/admin/groups/:groupID/users',
    element: <GroupUsers />
  },
  {
    path: '/admin/groups/:groupID/users/add',
    element: <AddUserInGroup />
  },
  {
    path: '/admin/cms_users',
    element: <CMSUsers />
  },
  {
    path: '/admin/cms_users/add',
    element: <CreateCMSUser />
  },
  {
    path: '/admin/cms_users/:userID/edit',
    element: <EditCMSUser />
  },
];

export const publicRoutes = [
  {
    path: "/admin/verify-login",
    element: <LoginVerified />,
  },
  {
    path: "/admin/access-denied",
    element: <AccessDenied />,
  },
  {
    path: '*',
    element: <NotFound />
  },
];

export const leftbarRoutes = [
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    icon: <MdDashboard />
  },
  {
    path: '/admin/users',
    name: 'Users',
    icon: <FaRegUserCircle />
  },
  {
    path: '/admin/projects',
    name: 'Projects',
    icon: <IoRocketOutline />
  },
  {
    path: '/admin/groups',
    name: 'Groups',
    icon: <GrGroup />
  },
];

export const titleMap = {
  "^\\/admin\\/dashboard\\/?$": 'Dashboard',
  "^\\/admin\\/users\\/?$": 'Users',
  "^\\/admin\\/users\\/(\\d+)\\/edit?$": 'User Edit',
  "^\\/admin\\/users\\/(\\d+)\\/plans/edit?$": 'Plan Edit',
  "^\\/admin\\/users\\/(\\d+)\\/projects\\/?$": 'User Projects',
  "^\\/admin\\/users\\/(\\d+)\\/projects\\/add\\/?$": 'Create Project',
  "^\\/admin\\/projects\\/?$": 'Projects',
  "^\\/admin\\/projects\\/(\\d+)\\/edit?$": 'Edit Project',
  "^\\/admin\\/groups\\/?$": 'Groups',
  "^\\/admin\\/groups\\/add\\/?$": 'Create Group',
  "^\\/admin\\/groups\\/(\\d+)\\/edit?$": 'Edit Group',
  "^\\/admin\\/groups\\/(\\d+)\\/users\\/?$": 'Group Users',
  "^\\/admin\\/groups\\/(\\d+)\\/users\\/add\\/?$": 'Add User',
  "^\\/admin\\/cms_users\\/?$": 'CMS Users',
  "^\\/admin\\/cms_users\\/add\\/?$": 'Create CMS User',
  "^\\/admin\\/cms_users\\/(\\d+)\\/edit?$": 'Edit CMS User',
  "^\\/admin\\/signin\\/?$": 'Sign In',
  "^\\/admin\\/signup\\/?$": 'Sign Up',
  "^\\/admin\\/access-denied\\/?$": 'Access Denied',
  ".*": "Not Found"
};