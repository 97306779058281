import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import EyeHide from '../assets/EyeHide';
import EyeShow from '../assets/EyeShow';
import Logo from '../assets/Seovigil-dark.png';
import TextInput from '../components/shared/TextInput';

const SingIn = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [responseError, setResponseError] = useState(false);
  const [error, setError] = useState<string | null>();

  const navigate = useNavigate();

  useEffect(() => {
    const checkLogin = async () => {
      const response = await fetch(`${BASE_URL}/check-login`, {
        method: 'GET',
        credentials: 'include'
      });

      if (response.ok) {
        navigate('/admin/dashboard');
      }
    };

    checkLogin();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password
        }),
        credentials: 'include'
      });

      if (response.ok) {

        navigate('/admin/dashboard');

      } else {
        const data = await response.json();
        setResponseError(true);
        setError(data.error);
      }
    } catch (error) {
      setResponseError(true);
      setError('Unkown Error Occured, Try Again!');

      if (error instanceof Error) {
        console.error(`Error during login: ${error.message}`);
      } else {
        console.error(`Error during login: Unknown error`);
      }
    }
  };

  return (
    <section className='bg-[#DFE5FD] md:h-screen'>
      <div className='h-screen px-4 mx-auto bg-white shadow-2xl max-w-screen-2xl md:px-0 md:mt-10 rounded-xl md:h-auto'>
        <div className='flex flex-col items-center justify-center gap-12 py-12'>
          <div className='w-72'>
            <img src={Logo} alt="Seovigil Logo" />
          </div>
          <h1 className='text-xl font-bold uppercase'>Admin Login</h1>
          {responseError &&
            <>
              <div className="flex flex-row justify-between w-full p-4 bg-red-500 rounded-md md:w-80 ">
                <p className="text-base font-semibold text-gray-50 ">
                  {error}
                </p>
                <p className="text-base font-semibold cursor-pointer text-gray-50" onClick={() => setResponseError(false)}>
                  X
                </p>
              </div>
            </>
          }
          <form className="relative space-y-8">
            <TextInput
              name='Email'
              id='email'
              placeholder={email}
              required={true}
              moveLabel={email != ''}
              type='email'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            />

            <TextInput
              name='Password'
              id='password'
              type={`${showPassword ? 'text' : 'password'}`}
              placeholder={password}
              required={true}
              moveLabel={password != ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            />
            <span className='absolute cursor-pointer top-20 right-2' onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <EyeShow /> : <EyeHide />}
            </span>

            <button
              type='submit'
              className={` ${!email || !password || responseError ? "cursor-not-allowed opacity-50" : ""} font-semibold py-2 px-8 uppercase custom-blue text-white rounded-lg w-full`}
              disabled={!email || !password || responseError}
              onClick={handleSubmit}
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SingIn;