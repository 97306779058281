import { useState } from 'react';

import { Link, NavLink, useNavigate } from 'react-router-dom';

import { TLeftbar } from '../../types';

import { AnimatePresence, motion } from 'framer-motion';

import { userPermissionStore } from '../../store';

import { AiOutlineBars } from "react-icons/ai";
import { FaRegUserCircle } from 'react-icons/fa';
import { GrGroup } from 'react-icons/gr';
import { IoIosLogOut } from 'react-icons/io';
import { IoRocketOutline } from 'react-icons/io5';
import { MdDashboard } from 'react-icons/md';
import { RiAdminFill } from "react-icons/ri";
import { RxCross1 } from "react-icons/rx";
import Logo from '../../assets/Seovigil.png';

const Leftbar = ({ toggleLeftbar, showLeftbar, userData }: TLeftbar) => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();

  const { permissions } = userPermissionStore();

  const handleLogout = async () => {
    const confirm = window.confirm("Are you sure you want to logout?");

    if (!confirm) {
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/logout`, { credentials: 'include' });

      if (response.ok) {
        navigate('/admin/signin');
      }

    } catch (err) {
      console.error('Error loggin out: ', err);
    }
  };

  return (
    <>
      <motion.div
        animate={{
          width: showLeftbar ? '300px' : '62px'
        }}
        className='flex flex-col justify-between min-h-screen px-4 bg-custom-dark text-custom-light'
      >
        <div className='flex flex-col gap-8'>
          <div className='flex items-center justify-between mt-4'>
            <AnimatePresence>
              {showLeftbar && (
                <motion.span
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                  className='w-44'
                >
                  <Link to={'/admin/dashboard'} className=''>
                    <img src={Logo} alt="Seovigil Logo" className='' />
                  </Link>
                </motion.span>
              )}
            </AnimatePresence>

            <button onClick={toggleLeftbar} className='text-3xl'>
              {showLeftbar ? <RxCross1 /> : <AiOutlineBars />}
            </button>
          </div>

          <div className='flex flex-col gap-8 text-2xl sidebar '>
            <NavLink
              to={'/admin/dashboard'}
              className={({ isActive }) => (
                `${isActive ? 'pointer-events-none underline' : ''} flex gap-6 items-center hover:underline`
              )}
              onClick={showLeftbar ? toggleLeftbar : () => { }}
            >
              <div className='relative group'>
                <MdDashboard />
                <span className={showLeftbar ? "hidden" : "absolute left-0 invisible -top-10 text-base group-hover:visible bg-white border-custom-mid border-2 text-custom-mid p-2 rounded-md"}>Dashboard</span>
              </div>
              <AnimatePresence>
                {showLeftbar && (
                  <motion.div
                    initial={{
                      width: 0,
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                      width: "auto",
                      transition: {
                        duration: 0.5,
                      },
                    }}
                    exit={{
                      width: 0,
                      opacity: 0,
                      transition: {
                        duration: 0.5,
                      },
                    }}
                    className='text-xl md:text-2xl'
                  >
                    Dashboard
                  </motion.div>
                )}
              </AnimatePresence>
            </NavLink>

            {permissions.can_view_users &&
              <NavLink
                to={'/admin/users'}
                className={({ isActive }) => (
                  `${isActive ? 'pointer-events-none underline' : ''} flex gap-6 items-center hover:underline`
                )}
                onClick={showLeftbar ? toggleLeftbar : () => { }}
              >
                <div className='relative group'>
                  <FaRegUserCircle />
                  <span className={showLeftbar ? "hidden" : "absolute left-0 invisible -top-20 text-base group-hover:visible bg-white border-custom-mid border-2 text-custom-mid p-2 rounded-md"}>Seovigil Users</span>
                </div>
                <AnimatePresence>
                  {showLeftbar && (
                    <motion.div
                      initial={{
                        width: 0,
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                        width: "auto",
                        transition: {
                          duration: 0.5,
                        },
                      }}
                      exit={{
                        width: 0,
                        opacity: 0,
                        transition: {
                          duration: 0.5,
                        },
                      }}
                      className='text-xl md:text-2xl'
                    >
                      Users
                    </motion.div>
                  )}
                </AnimatePresence>
              </NavLink>
            }

            {permissions.can_view_projects &&
              <NavLink
                to={'/admin/projects'}
                className={({ isActive }) => (
                  `${isActive ? 'pointer-events-none underline' : ''} flex gap-6 items-center hover:underline`
                )}
                onClick={showLeftbar ? toggleLeftbar : () => { }}
              >
                <div className='relative group'>
                  <IoRocketOutline />
                  <span className={showLeftbar ? "hidden" : "absolute left-0 invisible -top-20 text-base group-hover:visible bg-white border-custom-mid border-2 text-custom-mid p-2 rounded-md"}>Seovigil User&apos;s Projects</span>
                </div>
                <AnimatePresence>
                  {showLeftbar && (
                    <motion.div
                      initial={{
                        width: 0,
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                        width: "auto",
                        transition: {
                          duration: 0.5,
                        },
                      }}
                      exit={{
                        width: 0,
                        opacity: 0,
                        transition: {
                          duration: 0.5,
                        },
                      }}
                      className='text-xl md:text-2xl'
                    >
                      Projects
                    </motion.div>
                  )}
                </AnimatePresence>
              </NavLink>
            }

            {permissions.can_view_groups &&
              <NavLink
                to={'/admin/groups'}
                className={({ isActive }) => (
                  `${isActive ? 'pointer-events-none underline' : ''} flex gap-6 items-center hover:underline`
                )}
                onClick={showLeftbar ? toggleLeftbar : () => { }}
              >
                <div className='relative group'>
                  <GrGroup />
                  <span className={showLeftbar ? "hidden" : "absolute left-0 invisible -top-20 text-base group-hover:visible bg-white border-custom-mid border-2 text-custom-mid p-2 rounded-md"}>Admin Panel Groups</span>
                </div>
                <AnimatePresence>
                  {showLeftbar && (
                    <motion.div
                      initial={{
                        width: 0,
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                        width: "auto",
                        transition: {
                          duration: 0.5,
                        },
                      }}
                      exit={{
                        width: 0,
                        opacity: 0,
                        transition: {
                          duration: 0.5,
                        },
                      }}
                      className='text-xl md:text-2xl'
                    >
                      Groups
                    </motion.div>
                  )}
                </AnimatePresence>
              </NavLink>
            }

            {permissions.can_view_cms_users &&
              <NavLink
                to={'/admin/cms_users'}
                className={({ isActive }) => (
                  `${isActive ? 'pointer-events-none underline' : ''} flex gap-6 items-center hover:underline`
                )}
                onClick={showLeftbar ? toggleLeftbar : () => { }}
              >
                <div className='relative group'>
                  <RiAdminFill />
                  <span className={showLeftbar ? "hidden" : "absolute left-0 invisible -top-20 text-base group-hover:visible bg-white border-custom-mid border-2 text-custom-mid p-2 rounded-md"}>Admin Panel Users</span>
                </div>
                <AnimatePresence>
                  {showLeftbar && (
                    <motion.div
                      initial={{
                        width: 0,
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                        width: "auto",
                        transition: {
                          duration: 0.5,
                        },
                      }}
                      exit={{
                        width: 0,
                        opacity: 0,
                        transition: {
                          duration: 0.5,
                        },
                      }}
                      className='text-xl md:text-2xl'
                    >
                      CMS Users
                    </motion.div>
                  )}
                </AnimatePresence>
              </NavLink>
            }
          </div>
        </div>

        <div
          className='flex mb-6 cursor-pointer'
          onClick={handleLogout}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <AnimatePresence>
            <motion.button
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              className={`${showLeftbar ? 'relative flex items-center justify-center w-full gap-2 px-4 py-2 text-white rounded-full custom-blue' : ''}`}
            >
              {showLeftbar && <span className='font-semibold'>Log Out</span>}
              <IoIosLogOut className='w-8 h-7' />
              {
                isHovered &&
                <span className='absolute top-0 p-2 -mt-16 text-sm font-semibold text-center text-gray-500 bg-white rounded-lg'>Signed as <br /> {userData?.email}</span>
              }
            </motion.button>
          </AnimatePresence>
        </div >
      </motion.div >
    </>
  );
};

export default Leftbar;